import React from 'react';
import data from '../../data/pages/services/estate-liquidation.json';
import {
  Layout,
  Container,
  OrderProcess,
  ContentBlock,
  ServiceFeed,
} from '../../components';

const EstateLiquidation = () => {
  const content = data.content;
  return (
    <Layout pageContext={data}>
      <Container addSpacers>
        {/* Estate Liquidation Statement */}
        <ContentBlock
          title={content.statement.title}
          content={content.statement.content}
          cta={content.statement.cta}
          buttonProps={{ styleType: 'flat', showArrow: true }}
        />

        {/* Order Processing */}
        <OrderProcess />

        {/* Service List */}
        <ServiceFeed exclude={['estate-liquidation']} scrollOnMobile />
      </Container>
    </Layout>
  );
};

export default EstateLiquidation;
